.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: fit-content;
  background-color: rgba(
    253,
    207,
    0,
    1
  ); /* Adjust the color and opacity as needed */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin: 0;
}

.card-row:hover .overlay {
  opacity: 0.5;
}

.arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30px, 50%);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.arrow-reverse {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(30px, 50%);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.card-row:hover .arrow {
  opacity: 1;
  transform: translate(0px, 50%);
}

.card-row:hover .arrow-reverse {
  opacity: 1;
  transform: translate(0px, 50%);
}

@media (max-width: 600px) {
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: none;
  }

  .card-row:hover .arrow {
    opacity: 1;
    transform: none !important;
  }

  .arrow-reverse {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: none;
  }

  .card-row:hover .arrow-reverse {
    opacity: 1;
    transform: none !important;
  }
}
