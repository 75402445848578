.rl {
  animation: slide-in 3s 0s forwards;
}

/* Define the animation keyframes for sliding in from right to left */
@keyframes slide-in {
  from {
    opacity: 0%;
    transform: translateX(40%);
  }
  to {
    transform: translateX(0%);
  }
}

/* Add a class to the element to trigger the reverse animation */
.lr {
  opacity: 100%;
  animation: slide-in-reverse 3s 0s forwards;
}

@keyframes slide-in-reverse {
  from {
    opacity: 0%;
    transform: translateX(-40%);
  }
  to {
    opacity: 100%;
    transform: translateX(0%);
  }
}
.backgroundImage {
  z-index: 5;
  background-size: cover;
  background-image: url("/public/images/mBg.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.bgAnim {
  z-index: 4;
  position: absolute;
  background: linear-gradient(-45deg, #FDCF00, #e73c7e, #23a6d5, #fd7600);
	background-size: 200% 100%;
  width: 100%;
  height: 100%;
  animation: gradient 10s ease-in-out infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.introText {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  z-index: 100;
  width: 50%;
  max-width: 50%;
  padding-left: 20px;
}

.introText h1 {
  color: #fff;
  text-align: left;
  margin: auto;
  font-weight: 600;
  font-size: 2.5rem;
}

@media only screen and (min-width: 700px) and (max-width: 1000px) {
  .introText {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    z-index: 100;
    width: 40%;
    max-width: 40%;
    padding-left: 20px;
  }
  .introText h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1700px) {
  .introText {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(0%, -50%);
    z-index: 100;
    width: 40%;
    max-width: 40%;
    padding-left: 20px;
  }
  .introText h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (min-width: 1700px) {
  .introText {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0%, -50%);
    z-index: 100;
    width: 40%;
    max-width: 40%;
    padding-left: 20px;
  }
  .introText h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .introText {
    width: 0 !important;
    display: none;
  }
  .bgAnim {
    z-index: 4;
    position: absolute;
    background: none;
    animation: none;
  }
}
