#scroll-to-top {
  display: none; /* hide the button by default */
  position: fixed; /* position the button fixed to the bottom right corner of the viewport */
  bottom: 65px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transform: translateX(100px); /* start the button offscreen to the right */
  z-index: 199 !important;
}

@keyframes pulse {
  0% {
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 10px;
  }
}

/* This is the CSS for the animation */
#scroll-to-top.show {
  display: flex;
  align-items: center;
  justify-content: center; /* show the button when the "show" class is added */
  animation: fadeIn 0.3s ease-in-out forwards, pulse 2s infinite; /* fade in the button and move it to the left */
}

#scroll-to-top.hide {
  animation: fadeOut 0.3s ease-in-out forwards; /* fade out the button and move it to the left */
}

@keyframes fadeIn {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
}
