.bottom-to-top {
  transform: translateY(10%);
  transition: transform 9s all;
}

.top-to-bottom {
  transform: translateY(-50%);
  transition: transform 9s all;
}

.visible {
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transition: all 1s !important;
}

@keyframes slide-in {
  from {
    transform: translateY(10%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.pbox {
  cursor: pointer;
}

.pbox img {
  transition: all 0.3s ease-in-out;
}

.pbox:hover img {
  transform: scale(1.1);
}

.pbox div p:nth-child(1) {
  color: #fdcf00;
}

.pbox div p:nth-child(2) {
  color: #000;
}

.pbox:hover p:nth-child(1) {
  color: #000;
}

.pbox:hover p:nth-child(2) {
  color: #fdcf00;
}
