.po {
  background-image: url(/public/images/portfolio/pbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.po .swiper-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.po .swiper-pagination {
  position: relative !important;
  text-align: center;
  margin-top: 20px;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.po .swiper-pagination-bullet-active {
  background: #fdcf00 !important;
}

.po .swiper-pagination-bullet {
  background: #fdcf00 !important;
}

.po .swiper-button-next,
.po .swiper-button-prev {
  color: #fdcf00 !important;
}

.po .swiper-button-next:after,
.po .swiper-button-prev:after {
  font-size: 3rem !important;
}

.pos {
  position: relative;
  width: fit-content !important;
}

.pos:hover {
  color: #fdcf00 !important;
  cursor: pointer;
}

.pos::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fdcf00;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.pos:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.pof {
  position: relative;
  padding: 10px 5px;
}

.pof::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  content: "";
  border-bottom: 5px solid #fdcf00;
  width: 0%;
  height: 20px;
  transition: width 0.3s ease-in-out;
}

.pof:hover::before {
  width: 90%;
}

.portfolio-box {
  /* Add your existing styles for the portfolio box here */
  /* ... */
  /* Add transition properties */
  transition-property: opacity, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  opacity: 0;
  transform: scale(0.9);
}

.portfolio-box.visible {
  opacity: 1;
  transform: scale(1);
}
