.blogItem {
  display: inline-block;
  animation: slide-right 10s linear infinite;
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.blogItem.paused {
  animation-play-state: paused;
}

.line::before {
  content: "";
  width: 20%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #fdcf00;
}

.inputS {
  box-shadow: 0 10px 15px 0 rgba(247, 159, 31, 0.1);
}

.inputS::placeholder {
  color: black !important;
}

.slick-dots li button:before {
  color: #fdcf00 !important;
}
