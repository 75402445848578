.navbarm {
  position: absolute;
  top: 4%;
  left: 0;
  right: 0;
  height: 80px;
  width: 80% !important;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  transition: top 0.3s ease-in-out;
  z-index: 9999;
}

.nav-list {
  position: absolute;
  z-index: 9999;
  padding: 10px 0px 0px 0px;
  top: -5%;
  left: -10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  width: 70px;
  font-size: 12px;
  border-radius: 50px;
  height: 0;
  display: none;
  transition: all 0.3s ease-in-out;
}

.open {
  display: block;
  opacity: 1;
  animation-name: navOpen;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0ms;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-play-state: running;
}

@keyframes navOpen {
  0% {
    opacity: 0;
    height: 0;
  }
  25% {
    height: 100px;
  }
  50% {
    opacity: 0.5;
    height: 200px;
  }
  75% {
    opacity: 0.8;
    height: 300px;
  }
  100% {
    opacity: 1;
    height: max-content;
  }
}

.close {
  opacity: 1;
  animation-name: navClose;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-play-state: running;
}

@keyframes navClose {
  0% {
    opacity: 1;
    height: max-content;
  }
  25% {
    opacity: 0.8;
    height: 300px;
  }
  50% {
    opacity: 0.5;
    height: 200px;
  }
  75% {
    opacity: 0.2;
    height: 100px;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.fa-circle-info {
  --fa-primary-color: #000000;
  --fa-secondary-color: #fffafa;
}

.nav-list i {
  font-size: 30px;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbarm.sticky {
  position: fixed;
  top: 10px;
}

.navbarm .navI {
  cursor: pointer;
  position: relative;
  font-weight: 500;
  color: white;
}

.navbarm .navI:hover {
  color: #fdcf00;
}

.navbarm .navI:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -10px;
  left: 0;
  background-color: #fdcf00;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.navbarm .navI:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.navBg {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.6), 0 4px 30px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.sectionName {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.sectionName p {
  font-weight: 900;
  font-size: 22px;
  color: #fdcf00;
  white-space: nowrap;
  overflow: hidden;
}

.typing-animation {
  animation: typing 0.8s steps(40, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.navBtnPulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 0;
  }
  75% {
    box-shadow: #ff69b400 0 0 0 10px;
  }
}
