.main-card {
  width: 243px;
  height: 405px;
  background: #1F1F1F;
  border-radius: 10px;
  position: relative;
  z-index: 0;
}

.main-card::before  {
  content: "";
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-image: -webkit-linear-gradient(top, #fdcf00 0%, #fdcf00 100%);
  background-image: linear-gradient(to bottom, #fdcf00 0%, #fdcf00 100%);
  transition: width 0.3s ease-in-out;
  z-index: 5;
}

.main-card:hover::before {
  width: 100%;
}

.main-image {
  position: absolute;
  border-radius: 10px;
  width: 240px;
  height: 275px;
  object-fit: cover;
  top: 45%;
  left: 0;
  transform: translateY(-50%) translateX(-20%);
  z-index: 10;
}

.main-text {
  position: absolute;
  text-align: left;
  bottom: 5%;
  left: 7%;
  z-index: 10;
}

.main-text p {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.name {
  color: #fdcf00;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
}

.position {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.main-icons {
  position: absolute;
  right: 10%;
  top: 45%;
  transform: translateY(-50%);
  z-index: 10;
}

.icons-list {
  display: flex;
  flex-direction: column;
}

.icons-list li, .icons-list a {
  font-size: 20px;
  color: #fdcf00;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.main-card:hover p,
.main-card:hover li,
.main-card:hover a {
  color: black !important;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

@media only screen and (max-width: 600px) { 
  .main-card::before  {
    content: "";
    position: absolute;
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(top, #fdcf00 0%, #fdcf00 100%);
    background-image: linear-gradient(to bottom, #fdcf00 0%, #fdcf00 100%);
    transition: width 0.7s ease-in-out;
    z-index: 5;
  }

  .name {
    color: #000;
    text-transform: uppercase;
  }

  .position {
    color: #000;
  }
  
  .icons-list li, .icons-list a {
    color: #000;
  }
}
