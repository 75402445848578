.links {
  transition: 0.6s;
  padding-left: 5px;
}

.links:hover {
  transform: translateX(15px);
}

.newsLDiv:hover i {
  color: white !important;
  transform: translateX(7px);
  transition: transform 0.3s ease-in-out;
}
