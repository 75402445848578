.ckDescription {
  margin: auto;
  background-color: white;
  text-align: left;
  color: black;
  border-radius: 10px;
  padding: 20px;
}

.ckDescription ul {
  list-style-type: initial !important;
}

.ckDescription ol {
  list-style-type: decimal !important;
}

.ckDescription h1,
.ckDescription h2,
.ckDescription h3,
.ckDescription h4,
.ckDescription h5 {
  color: #fdcf00;
}

.options {
  background-color: #fdcf00;
  width: fit-content !important;
  border-radius: 10px;
  padding: 15px 10px;
  margin: auto;
  text-align: center;
  box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.71);
  -webkit-box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: 0px 2px 20px -1px rgba(0, 0, 0, 0.71);
}

.options span:nth-child(1) {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  text-transform: capitalize;
  white-space: nowrap;
  min-width: 30%;
}

.options span:nth-child(2) {
  margin-left: 10px;
  color: black;
  font-weight: 800;
  text-align: left;
  margin: auto;
  padding-left: 3px;
}

@media only screen and (max-width: 600px) {
  .options span:nth-child(1) {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-transform: capitalize;
    white-space: nowrap;
    min-width: 100%;
  }
}
