.main2,
.mainBoxes,
.mainClose,
.photoBox {
  width: 100%;
  height: 100%;
  /* max-height: 880px !important; */
  overflow: hidden;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 100;
}

/* .photoBox {
  width: 400px !important;
} */
