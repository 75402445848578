.sd {
  background-image: url(/public/images/services/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.ckDescription {
  background-color: white;
  text-align: left;
  color: black;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.ckDescription ul {
  list-style-type: initial !important;
}

.ckDescription ol {
  list-style-type: decimal !important;
}

.ckDescription h1,
.ckDescription h2,
.ckDescription h3,
.ckDescription h4,
.ckDescription h5 {
  color: #fdcf00;
}
