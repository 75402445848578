.or .swiper-pagination {
  position: absolute !important;
  bottom: 30px !important;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.or .swiper-pagination-bullet-active {
  background: #fdcf00 !important;
}

.or .swiper-pagination-bullet {
  background: #fdcf00 !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fade-in 0.3s ease-in-out;
}
