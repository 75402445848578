.ckDescription {
  text-align: left;
  color: white;
}

.ckDescription ul {
  list-style-type: initial !important;
}

.ckDescription ol {
  list-style-type: decimal !important;
}

.ckDescription h1,
.ckDescription h2,
.ckDescription h3,
.ckDescription h4,
.ckDescription h5 {
  color: #fdcf00;
}

.ckDescription p {
  max-width: 870px;
  line-height: 35px;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 600px) {
  .ckTitle p span {
    font-size: 30px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .ckTitle p span {
    font-size: 50px !important;
  }
}
