.App {
  text-align: center;
  scroll-snap-type: y mandatory;
  scroll-snap-align: start;
  overflow-x: hidden;
  font-family: soho !important;
  background-color: #2a2c38;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

a:hover {
  color: #fdcf00 !important;
}

.invalid-feedback {
  text-align: left !important;
  color: #7b0808 !important;
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  color: #8b0000 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #fdcf00;
}

::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.swiper-vertical {
  height: 100%;
}

.swiper-pagination-bullet-active {
  background-color: #fdcf00 !important;
}

.swiper-pagination-bullet {
  background-color: #fdcf00 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fdcf00 !important;
}

.Toastify__toast-body {
  text-align: left !important;
}

.truncate-words {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Adjust this value to specify the number of lines to display */
}

@media only screen and (max-width: 600px) {
  .slick-prev {
    left: 10px !important;
  }

  .slick-next {
    right: 10px !important;
  }
}

.home-loader {
  animation: homeLoader 5s linear 5s forwards;
}

@keyframes homeLoader {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-120%);
  }
}

.shadowBtn {
  transition: transform 0.1s ease-in-out;
}

.shadowBtn:hover {
  transform: translate(-3px, -3px);
}

.loader-overlay {
  /* Styles for the overlay container */
  transition: transform 0.8s ease-out;
}

.swipe-up {
  transform: translateY(-100%);
  animation: swipeUpAnimation 0.8s forwards;
}

@keyframes swipeUpAnimation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 1;
  }
}
