.custom-btn2 {
  width: 150px;
  height: 40px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: grid;
  place-items: center;
  text-align: center;
}

.btn-15 {
  color: #fff !important;
  border: 1px solid#fdcf00;
  box-shadow: 0 0 5px #fdcf00, 0 0 5px #fdcf00 inset;
  z-index: 1;
  text-align: center;
}
.btn-15:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: #fdcf00;
  box-shadow:
   0 0 20px  #fdcf00;
  transition: all 0.3s ease;
}
.btn-15:hover {
  color: #000 !important;
}
.btn-15:hover:after {
  left: 0;
  width: 100%;
}
.btn-15:active {
  top: 2px;
}
