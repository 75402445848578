.custom-btn3 {
  width: 170px;
  height: 40px;
  /* padding: 10px 25px; */
  font-family: "Lato", sans-serif;
  font-weight: 700;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: grid;
  place-items: center;
  text-align: center;
  margin: auto;
}

.btn-16 {
  color: #000 !important;
  border: 1px solid#000;
  box-shadow: 0 0 5px #000, 0 0 5px #000 inset;
  z-index: 1;
  text-align: center;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: #000;
  box-shadow:
   0 0 20px  #000;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #fff !important;
}
.btn-16:hover:after {
  left: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}
