.sbg {
  background-image: url(/public/images/stages/Union.png);
  background-repeat: no-repeat;
  background-position-y: 60%;
  background-size: contain;
}

@media (max-width: 1000px) {
  .sbg {
    background-image: none;
  }
}

@media (min-width: 1600px) {
  .sbg {
    background-image: url(/public/images/stages/Union.png);
    background-repeat: no-repeat;
    background-position-y: 70%;
    background-position-x: 30%;
  }
}
